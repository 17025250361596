import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import CustomFetch from '../../components/fetch';

export default function ForgotPage() {
    const [email, setEmail] = useState('');

    const handleForgot = (e) => {
        if (e) {
            e.preventDefault();

            let _error = '';

            if (email === '') {
                _error += 'Please enter an email address.\r\n';
            }

            if (_error === '') {
                CustomFetch(
                    `${process.env.GATSBY_API_URL}/login/forgot`,
                    'POST',
                    {
                        email: email,
                        portal: 'true',
                    },
                    function (result) {
                        if (result.error === '') {
                            toast.success(
                                'The request has been submitted, an email will be sent shortly.'
                            );
                        } else {
                            toast.error(String(result.error));
                        }
                    },
                    function (error) {
                        toast.error(String(error));
                    }
                );
            } else {
                toast.error(String(_error));
            }
        }
    };

    return (
        <Layout>
            <Seo
                title="Portal"
                description="Client Portal Forgot Your Password"
            />
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12 col-bleed portal--content">
                            <h1>
                                Forgot Your Password
                                <span>
                                    Please enter the email address associated to
                                    your account.
                                </span>
                            </h1>
                            <form onSubmit={handleForgot}>
                                <div className="login">
                                    <div className="formItem">
                                        <label className="fi__label">
                                            <input
                                                type="text"
                                                name="email"
                                                value={email}
                                                onChange={(e) => {
                                                    const { value } = e.target;

                                                    setEmail(value);
                                                }}
                                                placeholder=""
                                            />
                                            <span className="fi__span">
                                                Email Address
                                            </span>
                                        </label>
                                    </div>
                                    <div className="formItem">
                                        <button className="btn" type="submit">
                                            Request Password Reset
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
